.aboutContainer{
  z-index: 4;
  background: var(--ui-background);
  max-width: 50%;
  width: 100%;
  height: auto;
  max-height: 90%;
  border-radius: 10px;
  margin:auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2)
}

.aboutContainerMobile{
  z-index: 4;
  background: var(--ui-background);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.aboutContentContainer{
  height: 100%;
  list-style-type: none;
  padding: 0 20px;
  max-height: auto;
  overflow:hidden;
  overflow-y:auto;
  
}

