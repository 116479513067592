.floatingSearchContainer{
  position: relative;
}

.floatingSearchButton {
  cursor: pointer;
}

.floatingSearchPopup {
  position: fixed;
  bottom: 20px; /* Ajusta la distancia desde la parte inferior de la pantalla */
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999; /* Ajusta el valor según sea necesario para asegurarte de que el pop-up esté por encima de otros elementos */
  width: 400px; /* Ajusta el ancho del pop-up según sea necesario */
  background: var(--ui-background);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 7px;
}

.floatingSearchInputContainer {
  position: relative;
  display: flex;
  align-items: center;
}



.clearIcon{
  height: 1em;
  width: 1em;
  fill: var(--text-secondary);
  cursor: pointer;
  transition: 0s;
  z-index: 10;
  margin-left: auto;
}

.clearIcon:hover{
  fill: var(--text-primary);
  transition-duration: 0.2s;
}

.searchResultsContainer{
  display: flex;
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
}
