.menuContainerKiosk {
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ui-background);
}

.menuInnerContainerKiosk {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0%
}

.centerContainerKiosk {
  position: relative;
  width: fit-content;
}

.projectTitleKiosk {
  text-align: center;
  font-size: 4vw;
  max-width: 100%;
  color: var(--text-primary);
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

@media (max-width: 768px) {
  .projectTitleKiosk {
    font-size: 5vw;
  }
}

@media (max-width: 480px) {
  .projectTitleKiosk {
    font-size: 4vw;
  }
}

@media (max-height: 600px) {
  .projectTitleKiosk {
    font-size: 6vw;
  }

}