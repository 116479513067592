.mobileMenuContainerOuter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.overflowMenuContainer{
  overflow-y: auto;
}

.mobileMenuContainer {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--ui-background);
  width: 80%;
  height: 100%;
  z-index: 4;
  display: flex;
  flex-direction: column;
}

.downContainer{

  width: 100%;
}


.mobileMenuContainerHidden {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background: white;
  float: left;
}

.mobileMenuToggleButton {
  cursor: pointer;
  background: var(--ui-background);
  outline: none;
  border: 0;
  padding: 0.5em;
}

.menuIcon {
  fill: var(--icon-fill);
  width: 5em;
  height: 4.5em;
}

.menuIconInverted {
  fill: var(--icon-fill);
  width: 5em;
  height: 5em;
}

.mobileMenuLogoContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
}

.aboutMobileContainer {
  z-index: 2;
  position: fixed;
  bottom: 1.5em;
  left: 1em;

}
.preferencesContainer{
  z-index: 2;
  bottom: 5em;
  left: 1em;
  
}
.menuLogoMobile {
  width: -webkit-fill-available;
  width: -moz-available;
  height: 100%;
  text-align: center;
  justify-self: center;
  display: flex;
}

.logoTextMobile {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color: var(--icon-fill);
  font-weight: 700;
  font-size: 1.2em;
  margin: auto;
}

.mobileMenuButton {
  width: 100%;
  background: var(--ui-background);
  color: var(--text-primary);
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 1.2em;
  margin: 0 .25em 0 .25em;
  padding: .78571429em 0.5em .78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 600;
  line-height: 1em;
  font-style: normal;
  text-align: left;
  text-decoration: none;
  border-radius: .28571429rem;
  background-position: center;
  transition: background 0s;
  margin: auto;
}
