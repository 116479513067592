.fullscreenMap{
  cursor: all-scroll !important;
  z-index: 1;
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
}

.mapContainer{
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 1;
}

.overlayButtonRight{
  z-index: 2;
  width: fit-content;
  position: fixed;
  bottom: 1.5em;
  right:1em;
}



.overlayButtonCenter{
  z-index: 2;
  width: fit-content;
  position: fixed;
  bottom: 50%;
  left: 45.5%;
}



.mapContainerSmall{
  height: 500px;
  width: 500px;
  margin: auto;
  margin-top: 20px;
}

.mapContainerSmallMobile{
  height: 70%;
  width: 80%;
  margin: auto;
}

.smallMap{
  cursor: all-scroll !important;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
}

