.loginContainer{
  z-index: 4;
  background: var(--ui-background);
  width: -webkit-fill-available;
  width: -moz-available;
  height: auto;
  max-width: 400px;
  max-height: 700px;
  border-radius: 5px;
  margin: auto;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: block;
  flex-direction: column;
  justify-content: space-between;
}



.loginContainerMobile{
  height: -webkit-fill-available;
  height: -moz-available;
  justify-content: space-between;
  margin: 0;
  z-index: 4;
  padding: 10px;
  background: var(--ui-background);
  width: -webkit-fill-available;
  width: -moz-available;
  display: block;
  flex-direction: column;
}

.loginContainerMobile:focus-within{
  box-shadow: 0 0 10px var(--ui-accent)
}

.loginForm{
  background: var(--ui-background);
  margin: auto;
  width: -webkit-fill-available;
  width: -moz-available;
  border: none;
  color: var(--text-primary);
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: 1em;
  border-bottom: 2px linear-gradient(to right, var(--accent-color) 50%, #808080 50%);
  resize: none;
  transition: border-bottom-color 2s;
}

.loginFormMobile{
  height: -webkit-fill-available;
  height: -moz-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}

.loginForm:focus{
  outline: none;
}

.buttonContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.buttonContainerMobile{
  display: flex;
  flex-direction: row;
}

.headerText2{
  padding: 0px;
  color: var(--text-primary);
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 2em;
}

.bottomPadding30{
  padding-bottom: 30px;
}



.someButtonFacebook{
  height: 44px;
  position: relative;
  float: right;
  border-radius: 2px;
  border:0px transparent;
  max-width: 175.2px;
  background: #4267b2;
  color: white;
}

.someButtonFacebook:hover{
  background: #4267b2;
  opacity: 0.9;
  transition: all 0.5s;
  cursor: pointer;
}

.someButtonFacebook .fa{
  margin-right: 5px;
}

.separatorLine{
  border-top: 1px solid var(--text-primary);
  position: relative;
  text-align: center;
  height: 15px;
  margin: 1em 1em 0 1em;
}

.separatorLineMobile{
  border-top: 1px solid var(--text-primary);
  position: relative;
  text-align: center;
  height: 15px;
  margin: 1em 0.1em 0 0.1em;
}

.separatorText{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--primary-text-color);
  font-weight: 500;
  position:relative;
  top: -0.7em;
  background: var(--ui-baseline);
  padding: 0 0.5em 0 0.5em;
}

.infoTextContainer{

}

@media screen and (min-width: 345px) and (max-width: 380px) {
  .someButtonFacebook{
    max-width: 150px;
  }
}

@media screen and (max-width: 350px) {
  .someButtonFacebook{
    max-width: 120px;
  }
}
