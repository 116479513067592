.menuContainer{
  z-index: 5;
  width: 100%;
  height: 4em;
  top: 0;
  background: var(--ui-background);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select:  none;
  box-shadow: 0 0 5px white;
}

.menuInnerContainer{
  display: flex;
  float: right;
  flex-direction: row;
  justify-content: space-between;
  flex: 10;
  margin: 0px 10px;
  padding: 0;
  width: -webkit-fill-available;
  width: -moz-available;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.menuLogo{
  display: flex;
  grid-area: logo;
  margin: 0;
  width: 100%;
  max-width: 18%;
  height: 4em;
  width: -moz-fit-content;
  width: fit-content;
}

.logoText{
  cursor: pointer;
  color: var(--icon-fill);
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  margin: auto 10px;
  font-weight: 700;
  font-size: 1.2em;
  padding: 10px;
}

.projectInfoButtonContainer {
  width: 40px;
  height: 40px;
  margin-top: 12px;
  fill: var(--icon-fill);
}

.centerContainer{
  position: abosulte;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  /* padding-right: 90px; */
}
.projectInfoButton{
  /*background: var(--primary-color);*/
  color: var(--text-secondary);
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 1.2em;
  margin: 0 .25em 0 .25em;
  padding: 0;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: .28571429rem;
  background-position: center;
  transition: background 0s;
  margin: auto;
}

.projectInfoButton:hover{
  color: var(--icon-fill);
}

.projectSelectContainer{
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 45%;
  margin: 0 1%;
}

.createProjectButton {
  color: var(--icon-fill);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 3em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 1.2em;
  margin: 0 .25em 0 .25em;
  padding: 0;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  background-color: inherit;
}

/*
.menuButtonList{
  color: var(--secondary-text-color);
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 4em;
  grid-area: right;
}
.menuItemText{
  
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 1.2em;
  display: block;
  font-weight: 500;
}
.menuListItem{
  cursor: pointer;
  float: right;
  margin: 0px;
  padding: 0px 15px;
  height: 4em;
  display: block;
  text-align: center;
  align-self: center;
  transition: 0s;
}
.menuListItem:hover{
  color: var(--primary-text-color);
  transition: 0.5s;
  background: var(--accent-color);
  height: 100%;
}
.accountDropDownContainer{
  float: right;
  height: fit-content;
  height: -moz-fit-content;
  width: 100%;
  top: 4em;
}
.maxWidthContainer{
  top: 0;
  max-width: 1200px;
  padding: 0px;
  margin: 0 auto;
  width: -webkit-fill-available;
  width: -moz-available;
  border-radius: 5px;
}
.accountItemContainer{
  display: flex;
  width: fit-content;
  width: -moz-fit-content;
}
*/