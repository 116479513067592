.dropDownSelectHidden{
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
}

.dropDownSelectVisible{
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  max-height: 700px;
}

.dropDownSelectCurrentItem{
  cursor: pointer;
  display: flex;
  flex: 0;
  flex-direction: row;
  justify-content: space-between;
  width: 17em;
}


@media screen and (max-width: 430px) {
  .dropDownSelectCurrentItem{
    width: 76%;
  }
  .activeItemText {
    font-size: 90%;
  }
  .activeItemTextActive{
    font-size:  80%;
  }
}

/* drop down Project Names*/

.activeItemText{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  width: 16.8em;
  font-size: 90%;
  color: var(--text-primary);
  text-align: left;
  text-transform: capitalize;
  text-shadow: none;
  font-weight: 700;
  line-height: 0.9em;
  padding: .6em 0.5em .6em;
  vertical-align: baseline;
  align-self: center;

}

.activeItemTextActive{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 1em;
  color: var(--primary-text-color);
  text-transform: none;
  text-shadow: none;
  font-weight: 600;
  line-height: 1em;
  padding: .78571429em 0.5em .78571429em;
  vertical-align: baseline;
  align-self: center;
}

.projectDropDownIcon{
  padding: 0 0.5em 0 0;
  margin: auto 0;
  float: right;
  fill: var(--secondary-text-color);
  transition: 0s;
  width: 2em !important;
  height: 2em !important;
  position: flex;
}

.projectDropDownIconActive{
  padding: 0 0.5em 0 0;
  margin: auto 0;
  float: right;
  fill: var(--primary-text-color);
  width: 2em;
  height: 2em;
  transition: 0s;
  transform: rotate(180deg);
}

@media screen and (max-width: 390px) {

}
