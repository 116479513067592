.languageDDContainer{
  background: var(--ui-background);
  justify-content: center;
  margin: auto;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.languageDDContainer:hover .languageDDCurrentItem{
  color: var(--icon-fill);
}

.languageDDContainer:hover .dropDownIcon{
  fill: var(--icon-fill);
  
}

.languageDDContainerActive{
  background: var(--ui-background);
  justify-content: center;
  width: 100%;
  margin: 8px 0 0 0;
  height: fit-content;
  text-align: left;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.languageDDCurrentItemContainer{
  display: flex;
  height: 3em;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 5px;
}

.languageDDItem{
  cursor: pointer;
  padding: 0px;
  height: 100%;
  text-align: left;
  align-self: auto;
  transition: 0s;
  color: var(--text-secondary);
}

.languageDDCurrentItem{
  cursor: pointer;
  display: table;
  padding: 0px;
  transition: 0s;
  width: 100%;
  color: var(--text-secondary);
}

.languageDDText{
  display:table-cell;
  vertical-align: middle;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 1.2em;
  font-weight: 700;
  text-align: left;
  width: fit-content;
}

/* david :This css change the style of the active language selected*/
.dropDownListItemActive {

  text-decoration: underline;
  border: 4px solid var(--ui-accent);
}
/*Mateusz*/

.icon-containerlan {
  position: relative;
  padding-top: 7px;
}


.dropdownLan {
  position: absolute;
  top: 55px; /* ajusta la posición vertical del dropdown */
  left: -35px; /* ajusta la posición horizontal del dropdown */
  width: 65px;
  display: none;

}

.iconLook{
  display: flex;
  fill: var(--icon-fill);
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}


.icon-containerlan:hover .dropdownLan,
.icon-containerlan.show .dropdownLan{
  display: block;
}
/*--------------------*/

.languageDDText:hover {
  color: var(--icon-fill);
}

.languageDDTextActive{
  display:table-cell;
  vertical-align: middle;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 1.2em;
  font-weight: 500;
  text-align: left;
  width: fit-content;
  color: var(--icon-fill);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.languageDDIcon{
  padding: 0;
  margin: 0;
  float: right;
  fill: var(--text-secondary);
}

.dropDownIcon2{
  background: url("../resources/arrow_drop_down-24px.svg") no-repeat center right var(--text-secondary);
}

.divider{
  height: 2px;
  background: var(--text-secondary);
}

