input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px var(--ui-baseline) inset !important;
    -webkit-text-fill-color: var(--text-primary) !important;
    vertical-align: top;
    caret-color: var(--text-primary);
    background: aliceblue;
    box-shadow: 0 0 0 30px var(--ui-baseline) inset !important;
    
}

.inputPrimary:-webkit-autofill,
.inputPrimary:-webkit-autofill:hover, 
.inputPrimary:-webkit-autofill:focus, 
.inputPrimary:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px var(--ui-background) inset !important;
    -webkit-text-fill-color: var(--text-primary) !important;
    box-shadow: 0 0 0 30px var(--ui-background) inset !important;
}

.inputContainer{
  background: var(--ui-background);
  margin: 20px 0px;
  padding: 5px 0;
  width: -webkit-fill-available;
  width: -moz-available;
  border: none;
  color: var(--text-primary);
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: 1em;
  resize: none;
}

.inputContainerPrimary{
  background: var(--ui-background);
  margin: 20px 0px;
  padding: 5px;
  width: -webkit-fill-available;
  width: -moz-available;
  border: none;
  color: var(--text-primary);
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: 1em;
  resize: none;
}

.inputFocusLine{
  position: relative;
  bottom: 2px;
  margin: auto;
  width: 0%;
  border-bottom: 2px solid var(--text-secondary);
  transition: linear width 0s;
}


.inputFocusLineComment{
  bottom: 2px;
}

.input{
  vertical-align: top;
  background: var(--ui-baseline);
  width: -webkit-fill-available;
  width: -moz-available;
  margin: 0px;
  overflow: hidden;
  color: var(--text-primary);
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: 1em;
  resize: none;
  padding: 5px;
  border-radius: 10px;
  border-bottom: 2px solid var(--ui-accent);
}

.input:focus{
  outline: none;
}

.input:focus + .inputFocusLine{
  border-bottom: 2px solid var(--text-primary);
  width: 97%;
  transition-duration: 0.3s;
}

.inputPrimary{
  background: var(--ui-baseline);
  display: flex;
  fill: var(--icon-fill);
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 30px;
  border-radius: 10px;
}

.inputPrimary:focus{
  outline: none;
}

.inputPrimary:focus + .inputFocusLine{
  border-bottom: 2px solid var(--text-primary);
  width: 97%;
  transition-duration: 0.3s;
}
.inputLogIn{
  vertical-align: top;
  background: var(--ui-background);
  width: -webkit-fill-available;
  width: -moz-available;
  border: none;
  margin: 0px;
  overflow: hidden;
  color: var(--text-primary);
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: 1em;
  resize: none;
  padding-left: 6px;
  padding-right: 6px;
  border-bottom: 2px solid #808080;
}

.inputLogIn:focus{
  outline: none;
}

.inputLogIn:focus + .inputFocusLine{
  border-bottom: 2px solid var(--text-primary);
  width: 97%;
  transition-duration: 0.3s;
}
.checkbox{
  background: var(--ui-baseline);
}

.inputContainer{
  background: var(--ui-background);
  margin: 20px 0px;
  padding: 5px 0;
  width: -webkit-fill-available;
  width: -moz-available;
  border: none;
  color: var(--text-primary);
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: 1em;
  resize: none;
}


