.userSettingsContainer{
    z-index: 4;
    background: var(--ui-background);
    width: 100%;
    max-width: 600px;
    max-height: 800px;
    border-radius: 5px;
    margin:auto;
    padding: 2%;
    height: auto;
    max-height: 90%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    box-shadow: 0px 0px 15px 5px grey;
  }
  
  .userSettingsForm{
    background: var(--ui-background);
    border-radius: 5px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 40px 0px 0px 0px;
  }
  
  .userSettingsFormMobile{
    border-radius: 5px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 10px;
  }
  
  .userSettingsContainerMobile{
    z-index: 4;
    background: var(--ui-background);
    width: 100%;
    height: 100%;
    margin:auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .deleteAccountButtonContainerMobile{
    margin: 0.5em 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .deleteAccountButtonContainer{
    margin: 1em 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }