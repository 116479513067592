.fullscreenMap {
  cursor: all-scroll !important;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mapContainerMobile {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 1;
}

.mobileNewButton {
  z-index: 4;
  position: absolute;
  bottom: 2%;
  right: 0;
  background: transparent;
  fill: #262222;
  border: 0;
}

.mobileProjectButton {
  z-index: 10;
  position: absolute;
  right: 0em;
  background: var(--ui-background);
  border: 0;
  font-size: 17px;
  top: 0;
  width: 100%;
  height: 76px;
  padding-bottom: 1px;
  padding-left: 80px;
}


.mobileProject {
  z-index: 4;
  position: absolute;
  bottom: 5em;
  right: 0;
  background: transparent;
  border: 0;
  top: 0;
}

.mobileListViewButton {
  z-index: 4;
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: pointer;
  background: transparent;
  outline: none;
  border: 0;
  padding: 0.5em;
}

.addIcon {
  width: 5em;
  height: 5em;
}

.listIcon {
  fill: var(--icon-fill);
  width: 5em;
  height: 5em;
}

.floatingSearchContainerMapMobile {
  z-index: 4;
  border-radius: 5px;
  max-height: 70%;
  height: fit-content;
  position: absolute;
  overflow: auto;
  top: 1em;
  left: 6em;
  right: 1em;
}

.icon-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.accountInfoContainer{
  position: fixed;
  top: 1em;
  right: 1em;
  z-index: 40000;
  fill: var(--icon-fill);
  width: 40px;
}

.icon {
  font-size: 24px;
}
.lazy{
  width: 90%;
}