.deleteProjectContainer{
  z-index: 4;
  background: var(--ui-background);
  width: -webkit-fill-available;
  width: -moz-available;
  height: auto;
  overflow: auto;
  max-width: 600px;
  max-height: 800px;
  border-radius: 5px;
  margin:auto;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.deleteProjectForm{
  background: var(--ui-background);
  border-radius: 5px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}
.info, .success, .warning, .error, .validation {
  margin: 10px 0px;
  padding: 8px 10px 8px 50px;
  background-repeat: no-repeat;
  background-position: 10px center;
}
.info {
  color: #00529B;
  background-color: #BDE5F8;
  background-image: url('https://i.imgur.com/ilgqWuX.png');
}
.success {
  color: #4F8A10;
  background-color: #DFF2BF;
  background-image: url('https://i.imgur.com/Q9BGTuy.png');
}
.warning {
  color: #9F6000;
  background-color: #FEEFB3;
  background-image: url('https://i.imgur.com/Z8q7ww7.png');
}
.error{
  color: #D8000C;
  background-color: #FFBABA;
  background-image: url('https://i.imgur.com/GnyDvKN.png');
}
.validation{
  color: #D63301;
  background-color: #FFCCBA;
  background-image: url('https://i.imgur.com/GnyDvKN.png');
}