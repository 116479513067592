.dropDownContainer{
  background: var(--ui-baseline);
  border: 10px solid var(--ui-background);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-width: 260px;
  width: auto;
  max-height: 400px;
  overflow-y: auto;
  z-index: 999;
}

@media screen and (max-width: 410px) {
  .dropDownContainer{
    width: 75%;
   
  }
}


.icons{
  opacity: 1; /* opacidad por defecto */
  transition: opacity 0.3s ease-in-out;
}
.icons:hover{
  opacity: 0.8;
}


.dropDownList{
  list-style-type: none;
  padding: 0;
  margin: 0;
  
}

.dropDownListItem{
  cursor: pointer;
  padding: 0px;
  height: 100%;
  width: auto;
  display: block;
  text-align: left;
  align-self: center;
  transition: 0s;
  color: var(--text-primary);
}

.dropDownItemText{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 1.2em;
  padding: 0.5em 0.5em 0.5em 0.5em;
  margin: 0;
}
.dropDownItemTextMobile{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 4.5vw;
  padding: 0.5em 0.5em 0.5em 0.5em;
  margin: 0;
}


.dropDownListItem:hover{
  border-radius: 10px;
  transition: 0.5s;
  background: var(--ui-accent);
  height: 100%;
}