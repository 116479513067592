.postViewContainer{
  z-index: 4;
  width: -webkit-fill-available;
  width: -moz-available;
  height: -webkit-fill-available;
  height: -moz-available;
  background: var(--ui-background);
  height: 100%;
  width: 100%;
  max-width: calc(50% - 20px);
  max-height: calc(90% - 20px);;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
  border-radius: 10px;
}

.postViewContainerLW{
  z-index: 4;
  background: var(--ui-background);
  width: 65%;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
}

.postViewContainerMobile{
  z-index: 4;
  background: var(--ui-background);
  width: -webkit-fill-available;
  height: 100%;
  padding: 1% 0;
  width: 100%;
  margin:auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.postTitleContainer{
  width: -webkit-fill-available;
  width: -moz-available;
  max-width: 100%;
  max-height: fit-content;
  max-height: -moz-fit-content;
  display: flex;
  justify-content: space-between;
  padding: 2%;
  }
.postCloseButtonContainer{
  width: 4%;
}
.postTitleContainerMobile{
  width: -webkit-fill-available;
  width: -moz-available;
  max-width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.verifiedIcon{
  fill: var(--text-primary);
  height: 1em;
  width: 1em;
  padding-left: 1em;
}

.verifiedIconListView{
  fill: var(--text-primary);
  height: 2em;
  width: 2em;
}

.verifiedIcon{
  fill: var(--text-primary);
  height: 1em;
  width: 1em;
  padding-left: 0.4em;
}

.arrow_back{
  fill: var(--text-secondary);
  height: 2em;
  width: 2em;
}

.verifiedIconMobile{
  fill: var(--text-primary);
  height: 1,5em;
  width: 1,5em;
  padding-left: 0.4em;
  
}

.postTitleHeader{
  display: flex;
  width: -webkit-fill-available;
  width: -moz-available;
  height: 100%;
  justify-content: center;
  overflow-x: auto;
}

.postTitleTextMobile{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--icon-fill);
  font-weight: 700;
  font-size: 1.5em;
  text-align: center;
  margin: auto;
}

.postImageContainer{
  height: -webkit-fill-available;
  height: -moz-available;
  max-width: 100%;
  max-height: 50%;
  padding: 10px;
  display: flex;
}

.postImage{
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  display: flex;
  margin: auto;
  padding: auto;
  object-fit: contain;
}

.placeholderImage{
  width: -webkit-fill-available;
  width: -moz-available;
  height: -webkit-fill-available;
  height: -moz-available;
  width: 100%;
  height: 100%;
  background: gray;
}

.postContextContainer{
  display: flex;
  justify-content: space-between;
  height: fit-content;
  height: -moz-fit-content;
  max-width: 100%;
  padding: 0 10px;
}

.postButtonsContainer{
  justify-self: flex-end;
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  height: -moz-fit-content;
}

.postButtonsContainerInner{
  display: flex;
}

.postButtonsContainerInnerMobile{
  display: flex;
  flex-direction: column;
}

.storyContainer{
  max-width: 100%;
}


.postCloseContainer{
  width: -webkit-fill-available;
  width: -moz-available;
  height: -webkit-fill-available;
  height: -moz-available;
  max-height: fit-content;
  max-height: -moz-fit-content;
  padding: 10px;
}

.postButtonsParantolat{
  padding-top: 20px;
  justify-content: center;
  display: flex;
  max-width: 100%;
}

.memoryOwnerButtonsContainer{
  padding: 5px 0 5px 0;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
}

.memoryOwnerButton{
  margin: 5px;
}
