.normalText{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-primary);
  font-weight: 500;
  text-align: left;
}

.projectInfoText{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-primary);
  font-weight: 500;
  text-align: center;
}

.normalText p{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-secondary);
  font-weight: 500;
  text-align: left;
}
.normalTextNoMargin{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-primary);
  font-weight: 500;
  margin: 0;
  text-align: left;
}

.textCenter{
  text-align: center;
}

.titleText{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-primary);
  font-weight: 700;
  text-align: center;
  padding: 0;
  margin: 0;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.titleTextAuto{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-primary);
  font-weight: 700;
  font-size: 2em;
  text-align: center;
  margin: auto;
}

.linkText{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-primary);
  font-weight: 500;
  cursor: pointer;
  padding: 5px 0;
  margin: 0 0 10px 0;
  width: fit-content;
  width: -moz-fit-content;
}

.titleTextMobile{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-primary);
  font-weight: 700;
  font-size: 1.5em;
  text-align: center;
  margin: auto;
}

.noHorizontalMargin{
  margin: auto 0;
}

.boldText{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-primary);
  font-size: 1.2em;
  text-transform: none;
  text-shadow: none;
  font-weight: 600;
  line-height: 1em;
  text-align: left;
}

.smallText{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-primary);
  font-weight: 500;
  font-size: 1em;
  text-align: left;
}

.noMarginText{
  margin: 0;
}

.infoText{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-primary);
  font-weight: 400;
  text-align: center;
  padding-top: 0.75em
}

h1.titleText, h1.titleTextMobile {
  color: var(--text-primary);
  text-align: center;
  margin-top: 15px;
  font-size: 150%;
}
