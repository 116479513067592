

.acountDDContainerActive{
    background: var(--ui-background);
    justify-content: center;
    width: auto;
    margin: 8px 0 0 0;
    height: fit-content;
    text-align: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.acountDDCurrentItemContainer{
    display: flex;
    height: 3em;
    flex-direction: column;
    justify-content: center;
    text-align: center;

}


.acountDDCurrentItemContainerKiosk{
    display: flex;
    height: 0%;
    flex-direction: column;
    justify-content: center;
    text-align: center;

}

