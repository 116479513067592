.imageUploadContainer{
  display: flex;
  margin: 20px 0;
}

.imageUploadContainerMobile{
  display: flex;
  flex-direction: column;
}

.imagePreviewContainer{
  min-width: 320px;
  width: 320px;
  height: 180px;
  display: flex;
}

.imagePreviewContainerMobile{
  width: 100%;
  height: 280px;
  max-height: 280px;
  display: flex;
  margin: auto;
}

.fileInput{
}

.previewText{
  text-align: center;
  margin: auto;
}

.selectImageContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

input[type="file"]{
  display: none;
}

.previewImage{
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  margin: auto;
}

.previewImageMobile{
  height: auto;
  width: auto;
  max-height: 280px;
  max-width: 100%;
  border-radius: 5px;
  margin: auto;
}

.marginAuto{
  margin: auto;
}