.myPostsContainer{
  z-index: 4;
  background: var(--ui-background);
  max-width: 50%;
  min-width: 400px;
  height: auto;
  max-height: 90%;
  border-radius: 5px;
  margin:auto;
  display: flex;
  flex-direction: column;
}

.myPostsContainerMobile{
  z-index: 4;
  background: var(--ui-background);
  width: 100%;
  height: 100%;
  margin:0;
  display: flex;
  flex-direction: column;
}

.myPostsList{
  list-style-type: none;
  z-index: 4;
  background: var(--ui-background);
  height: 100%;
  width: 97.5%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px;
  margin: 15px;
}

.myPostsListItem{
  max-height: 100px;
  list-style-type: none;
  cursor: pointer;
  background: var(--ui-baseline);
  border-radius: 10px;
  border: 5px solid transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px;
  padding: 10px;
  transition: 0s;
 
}

.myPostsListItem:hover{
  transition: 0.2s;
  border: 5px solid var(--ui-accent);
  border-radius: 10px;
}
