.projectInfoContainer {
  z-index: 4;
  background: var(--ui-background);
  max-width: 50%;
  width: 100%;
  height: auto;
  max-height: 90%;
  border-radius: 10px;
  margin:auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
@media (orientation: portrait) {
  .projectInfoContainer{
    z-index: 4;
    background: var(--ui-background);
    max-width: 70%;
    width: 100%;
    height: auto;
    max-height: 90%;
    border-radius: 10px;
    margin:auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
}
.projectInfoContainerMobile {
  z-index: 4;
  background: var(--ui-background);
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.projectInfoDescriptionContainer {
  margin: 10px;
  text-align: left;
  display: block;
}

.projectInfoContentDescriptionContainer {
  margin: 10px;
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  display: block;
}

.projectInfoImageContainer {
  max-width: auto;
  display: block;
  
}

.projectInfoImage {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border-radius: 21%;
}

.settingIcon{
  cursor: pointer;
  width: 2.2em;
  height: 2.2em;
  margin: 2px 0 0 10px;
}

.projectInfoContentContainer{
  height: 100%;
  list-style-type: none;
  padding: 2%;
  max-height: auto;
  overflow:hidden;
  overflow-y:auto;
}

.projectInfoDownloadButton{
  text-align: center;
  margin-top: 2em;
}

.showMore{
  background: var(--button-background);
  border-radius: 7px;
  transition: background 0s;
  margin-bottom: 15px;
}
