.postListContainer{
  z-index: 4;
  width: -webkit-fill-available;
  width: -moz-available;
  border-radius: 10px;
  margin:auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.postListList{
  width: 100%;
  height: 100%;
  list-style-type: none;
  padding: 0 10px 0 10px;
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
}
.postSearchList{
  list-style-type: none;
  z-index: 4;
  width: -webkit-fill-available;
  width: -moz-available;
  width: 97.5%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding: 0px;
  margin: 10px;
}

.postListListAdmin{
  height: 100%;
  list-style-type: none;
  padding: 10px;
  max-height: auto;
  overflow:hidden;
  overflow-y:auto;
}

.postListItem{
  cursor: pointer;
  background: var(--ui-background);
  display: flex;
  margin: 5px 0;
  padding: 10px;
  border: 5px solid transparent;
  border-radius: 10px;
  width: calc(100% - 50px)
}
.postListItemMobile{
  cursor: pointer;
  background: var(--ui-background);
  display: grid;
  margin: 5px 0;
  padding: 10px;
  border: 5px solid transparent;
  border-radius: 10px;
  width: calc(100% - 50px)
}

.postViewListItem{
  cursor: pointer;
  background: var(--ui-baseline);
  display: flex;
  margin: 0 0 10px 0;
  padding: 10px;
  border: 5px solid transparent;
  border-radius: 10px;
  width: calc(100% - 40px);
}
.postViewListItem:focus{
  border: 5px solid var(--icon-fill);
  border-radius: 10px;
}
.postViewListItem:hover{
  transition: 0.2s;
  border: 5px solid var(--icon-fill);
  border-radius: 10px;
}


.postListItemImageContainer{
  width: 35%;
  height: 100%;
  margin: auto 0.5em auto 0;
  align-content: center;
  display: flex;
}
.postListReportFlags{
  width: 30%;
}


.postListImagePreview{
    max-height: 100%;
    max-width: 100%;
    border-radius: 5px;
    margin: auto;
    display: flex;
}


.postListItemInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
  cursor: pointer;
}

.postListTitle{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-primary);
  font-weight: 700;
  font-size: 1.2em;
  margin: 0;

}

.postListTitleMobile{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-primary);
  font-weight: 700;
  font-size: 1.2em;
  margin: 0;
  padding-left: 10px;
  
}

.postListText{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: var(--text-primary);
  font-weight: 500;
  font-size: 1em;
  margin: 0;
}

/* CSS for samller screen - by Adnan Islam */



