:root{
  --primary-color: #000000;
  --secondary-color: #202124;

  --primary-text-color: #ffffff;
  --secondary-text-color: #a0a0a0;

  --accent-color: #383838;
  --accent-color-dark: #1b1c1d;
  --accent-color-light: #808080;
  --effect-color: #cecece;
  --primary-color: #000000;
  --title-color:#03FFFF;
  --hover-text-color: #000000;
}


html, body, #root, #app, #app>div {
  height: auto;
  min-height: 100%;
 
}

.appContainer{
  background-color: #161616;
  margin: 0px;
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
}

.popUpContainer{
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
}

.popUpBackground{
  background: var(--pop-background);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  overflow: hidden;
}

.contentContainer{
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 1;
}

.toggleDescriptionButtonContainer{
  text-align: center;
  z-index: 10;
}
.titleContainerMobile{
  width: 100%;
  height: fit-content;
  max-width: 100%;
  margin: 0 0 2em 0;
  display: flex;
  flex-direction: row;
}

.titleHeaderMobile{
  display: flex;
  width: 75%;
}

.dualButtonContainer{
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1em 0;
  justify-content: space-between;
}

.closeContainer{
  width: -webkit-fill-available;
  width: -moz-available;
  max-height: fit-content;
  padding: 0 10px;
}