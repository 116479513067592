.newProjectContainer{
  z-index: 4;
  background: var(--ui-background);
  width: -webkit-fill-available;
  width: -moz-available;
  height: 100%;
  overflow: auto;
  max-width: 600px;
  max-height: 800px;
  border-radius: 5px;
  margin:auto;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.newProjectForm{
  background: var(--ui-background);
  border-radius: 5px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}