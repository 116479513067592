.horizontalMenuContainerLogged{
  display: flex;
  flex-direction: column;
  grid-area: navMenu;
  width: auto;
}

.horizontalMenuContainer{
  display: flex;
  flex-direction: column;
  grid-area: navMenu;
  width: auto;
}


.menuButtonList{
  color: var(--text-primary);
  float: right;
  margin: 0 10px;
  padding-left: 0;
}

.menuItemText{
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 1.2em;
  display: block;
  font-weight: 600;
  margin: auto;
}

.menuListItem{
  cursor: pointer;
  float: right;
  display: flex;
  text-align: center;
  align-self: center;
  transition: 0s;
  margin: 0 5px;
}



.personIcon{
  padding: 0;
  margin: auto;
  fill: var(--icon-fill);
  width: 2em;
  height: 2em;
}


.dropDownIcon{
  padding: 0;
  margin-top: 6px;
  float: right;
  fill: var(--text-primary);
  transition: 0.3s;
  width: 2em !important;
  height: 2em !important;
}

.dropDownIcon:hover {
  color: var(--icon-fill);
}

.dropDownIconActive{
  padding: 0;
  margin-top: 6px;
  float: right;
  fill: var(--icon-fill);
  width: 2em;
  height: 2em;
  transition: 0.3s;
  transform: rotate(180deg);
}

/*Mateusz CSS*/
.icon-container {
  position: relative;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.dropdown {
  position: absolute;
  top: 55px; /* ajusta la posición vertical del dropdown */
  left: -190px; /* ajusta la posición horizontal del dropdown */
  width: 205px;
  background: var(--ui-baseline);
  border: 10px solid var(--ui-background);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: none;
  border-radius: 10%;
}

.dropdownContainer{
  position: absolute;
  top: 60px; /* ajusta la posición vertical del dropdown */
  left: -190px; /* ajusta la posición horizontal del dropdown */
  width: 50px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid #000000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: none;
  border-radius: 5%;
}

.icon-container:hover .dropdown,
.icon-container.show .dropdown{
  display: block;
}

