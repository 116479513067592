.leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
	-webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
	-moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
	-o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
	transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
	/* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
	-webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
	-moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
	-o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
	transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

.marker-cluster-small {
	background-color: rgba(30, 130, 205, 0.5);
	}
.marker-cluster-small div {
	background-color: rgba(30, 135, 210, 0.9);
	}

.marker-cluster-medium {
	background-color: rgba(90, 95, 185, 0.5);
	}
.marker-cluster-medium div {
	background-color: rgba(90, 100, 190, 0.9);
	}

.marker-cluster-large {
	background-color: rgba(90, 100, 180, 0.5);
	}
.marker-cluster-large div {
	background-color: rgba(90, 100, 180, 0.9);
	}

	/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
	background-color: rgb(30, 130, 205);
	}
.leaflet-oldie .marker-cluster-small div {
	background-color: rgb(30, 135, 210);
	}

.leaflet-oldie .marker-cluster-medium {
	background-color: rgb(90, 95, 185);
	}
.leaflet-oldie .marker-cluster-medium div {
	background-color: rgb(90, 100, 190);
	}

.leaflet-oldie .marker-cluster-large {
	background-color: rgb(90, 95, 185);
	}
.leaflet-oldie .marker-cluster-large div {
	background-color: rgb(90, 100, 190);
}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
	}
.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	font: 13px "Helvetica Neue", Arial, Helvetica, sans-serif;
	font-weight: 500;
	color: white;
	}
.marker-cluster span {
	line-height: 30px;
	}
